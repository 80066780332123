.carousel-root,.carousel, .carousel-slider, .slider-wrapper, .axis-horizontal, .slider, .animated {
    height: 100% !important;
    background-color: black;
  }
  .BrainhubCarousel{
      height: 100%;
  }
.control-arrow, .control-prev{
    background-color:black !important;
    height: 100%;
}